import { render, staticRenderFns } from "./SideBar.vue?vue&type=template&id=04254ae0&scoped=true&"
import script from "./SideBar.vue?vue&type=script&lang=js&"
export * from "./SideBar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04254ae0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VCardSubtitle,VDivider,VList,VListItemGroup,VProgressLinear,VSubheader})
