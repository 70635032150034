<template>
  <BaseDetail v-if="detail">
    <template #header>
      <nobr></nobr>
    </template>
    <template #sidebar>
      <BaseSideBar dark>
        <v-card flat>
          <v-card-text>
            <SideBar
              :passport-name="detail.passport.properties.object_name"
              :passport-entities="detail.passport.properties.passportEntities"
              :passport-id="detail.passport.properties.id"
            />
          </v-card-text>
        </v-card>
      </BaseSideBar>
    </template>
    <template #content>
      <router-view
        :key="$route.fullPath"
        v-if="detail"
        v-model="detail"
        ref="content"
      ></router-view>
    </template>
  </BaseDetail>
</template>

<script>
import BaseHeader from '@/components/base/BaseDetail/BaseHeader'
import BaseDetail from '@/components/base/BaseDetail'
import DetailView from '@/components/views/account/verification/detail/DetailView'
import BasePreloader from '@/components/base/UI/BasePreloader'
import detail from '@/components/mixins/detail'
import ListItemObject from '@/components/views/account/verification/detail/sidebar/ListItemObject'
import BaseListItem from '@/components/base/BaseNav/BaseListItem'
import BaseSideBar from '@/components/base/BaseSideBar'
import SideBar from '@/components/views/account/verification/detail/SideBar'

export default {
  name: 'Detail',
  mixins: [detail],
  components: { BaseHeader, BaseDetail, DetailView, BasePreloader, ListItemObject, BaseListItem, BaseSideBar, SideBar },
  data () {
    return {
      controller: 'verification'
    }
  }
}
</script>

<style scoped>
  .active {
    background: #4d77c5;
  }
</style>
