<template>
  <v-list-item
    dense
    dark
    :title="item.eavEntity.entityName"
    @click="handleVerificationObjectItem"
    active-class="active"
  >
    <v-list-item-icon class="rounded white pa-1">
      <v-icon
        style="text-shadow: 1px 1px 1px rgba(0,0,0,0.4)"
        dense
        :color="item.eavEntity.style.color"
        v-text="item.eavEntity.icon"
      ></v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title v-text="item.eavEntity.entityName"></v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'ListItemObject',
  props: {
    item: Object
  },
  methods: {
    handleVerificationObjectItem () {
      if (this.$route.name !== 'map') {
        this.$router.push({ name: 'commitObject', params: { commitId: this.item.eavEntity.id } }).catch(error => {
          if (
            error.name !== 'NavigationDuplicated' &&
            !error.message.includes('Avoided redundant navigation to current location')
          ) {
            console.log(error)
          }
        })
      } else {
        this.$emit('click', this.item.eavEntity.id)
      }
    }
  }
}
</script>
<style scoped>
.active {
  background: #4d77c5;
}
</style>