<template>
  <div>
    <v-card-subtitle class="pb-1 pl-8 pt-0">Верификация данных</v-card-subtitle>
    <v-list
      dense
      dark
    >
      <v-list-item-group
        mandatory
        v-model="currentActiveItem"
      >
        <v-subheader>{{ passportName }}</v-subheader>
        <v-divider></v-divider>
        <BaseListItem
          v-for="(item,i) in getMenuItems"
          :key="i+ '-'"
          :item="item"
          :is-map="isMap"
          @click="$emit('click', $event)"
        />
        <v-divider></v-divider>
        <v-subheader>Объекты</v-subheader>
        <v-progress-linear
          v-if="loading"
          indeterminate
          rounded
          height="3"
          class="mb-2"
          color="primary"
        ></v-progress-linear>
        <ListItemObject
          v-for="(item, i) in itemsVerification"
          :key="i"
          :item="item"
          @click="$emit('click', $event)"
        />
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import { collectMenuItems } from '@/components/utils/common'
import BaseListItem from '@/components/base/BaseNav/BaseListItem'
import ListItemObject from '@/components/views/account/verification/detail/sidebar/ListItemObject'
import _ from 'lodash'

export default {
  name: 'SideBar',
  components: { ListItemObject, BaseListItem },
  props: {
    passportEntities: Array,
    passportName: String,
    passportId: Number,
    isMap: Boolean
  },
  data () {
    return {
      currentActiveItem: null,
      menuItems: [
        {
          menuItem: 'InfoAboutCommit',
          role: null,
          icon: 'info'
        }
      ],
      itemsVerification: [],
      loading: false
    }
  },
  computed: {
    getMenuItems () {
      return collectMenuItems(this.menuItems)
    }
  },
  methods: {
    createCurrentActiveItem () {
      const currentId = Number(this.$route.params.commitId)
      const findCurrentActiveElement = this.itemsVerification.findIndex((item) => item.eavEntity.id === currentId)
      this.currentActiveItem = findCurrentActiveElement + 1
    },
    async getDataVerification () {
      this.loading = true
      for (let item of this.passportEntities) {
        const response = await this.$store.dispatch('server/get', {
          url: '/object?',
          params: this.pagination(item.eavEntity.id)
        })
        if (!!response.count && this.isConsistNeededChanges(response.models)) {
          this.itemsVerification.push(item)
        }
      }
      this.createCurrentActiveItem()
      this.loading = false
      return true
    },
    isConsistNeededChanges (models) { //note: костыль для скрытия ненужных элементов сайдбара верификации
      let result = false
      for (let i = 0; i < models.length;) {
        const item = models[i]
        const origin = item.properties.origin
        if (origin) {
          const extraAttributes = ['origin', 'state', 'id', 'created_at', 'updated_at', 'origin_id', 'verification_status', 'passport_id', 'eav_entity_id'] //список непроверяемых свойств
          const keysProperties = _.keys(item.properties) //список ключей в виде массива
          const differenceProperties = _.omit(_.reduce(keysProperties, function (result, key) { //создание объекта с отличающимися свойствами
            if (item.properties[key] && Object.keys(origin).length !== 0 && !_.isEqual(item.properties[key], origin.properties[key])) {
              result[key] = true
            }
            return result
          }, {}), extraAttributes)

          if (!!Object.keys(differenceProperties).length || (item.properties.state === 2 && origin.properties.state !== 2)) {
            // есть изменения или удаленный (не копия удаленного)
            result = true
            break
          }
        } else {
          // новый
          result = true
          break
        }

        i++
      }
      return result
    },
    pagination (eavId) {
      const paginationData = {}
      paginationData.options = {
        page: 1,
        itemsPerPage: 10
      }
      paginationData.filter = {
        eav_entity_id: eavId,
        passport_id: this.passportId,
        verificationScenario: 'diff'
      }
      return paginationData
    }
  },
  async mounted () {
    if (this.passportEntities) {
      return this.getDataVerification()
    }
  }
}
</script>

<style scoped>

</style>
